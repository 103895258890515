const Nav = () => {
    const template = /*html*/ `
    <nav>
      <h1>Nebojša Brnjašević</h1>
      <ul>
        <li>
            <a>Section 1</a>
        </li>
        <li>
            <a>Section 2</a>
        </li>
        <li>
            <a>Section 3</a>
        </li>
        <li>
            <a>Section 4</a>
        </li>
      </ul>
    </nav>
  `;

    return template;
};

export default Nav;
